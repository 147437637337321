<template>
  <ManageCustomList dialog-name="Custom Lists" />
</template>
<script>
import ManageCustomList from '@/views/app/manage/CustomList'

export default {
  name: 'CustomListWrapper',
  components: {
    ManageCustomList
  }
}
</script>
